.gctClient-app *,
.gctClient-app:before,
.gctClient-app:after {
    box-sizing: border-box;
    border: 0 solid #e5e7eb
}

.gctClient-app:before,
.gctClient-app:after {
    --tw-content: ""
}

.gctClient-app,
.gctClient-app:host {
    -webkit-text-size-adjust: 100%;
    tab-size: 4;
    font-feature-settings: normal;
    font-variation-settings: normal;
    -webkit-tap-highlight-color: transparent;
    font-family: Delivery, sans-serif;
    line-height: 1.5
}

.gctClient-app {
    line-height: inherit;
    margin: 0
}

.gctClient-app hr {
    color: inherit;
    border-top-width: 1px;
    height: 0
}

.gctClient-app abbr:where([title]) {
    text-decoration: underline dotted
}

.gctClient-app h1,
.gctClient-app h2,
.gctClient-app h3,
.gctClient-app h4,
.gctClient-app h5,
.gctClient-app h6 {
    font-size: inherit;
    font-weight: inherit
}

.gctClient-app a {
    color: inherit;
    text-decoration: inherit
}

.gctClient-app b,
.gctClient-app strong {
    font-weight: bolder
}

.gctClient-app code,
.gctClient-app kbd,
.gctClient-app samp,
.gctClient-app pre {
    font-feature-settings: normal;
    font-variation-settings: normal;
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
    font-size: 1em
}

.gctClient-app small {
    font-size: 80%
}

.gctClient-app sub,
.gctClient-app sup {
    vertical-align: baseline;
    font-size: 75%;
    line-height: 0;
    position: relative
}

.gctClient-app sub {
    bottom: -.25em
}

.gctClient-app sup {
    top: -.5em
}

.gctClient-app table {
    text-indent: 0;
    border-color: inherit;
    border-collapse: collapse
}

.gctClient-app button,
.gctClient-app input,
.gctClient-app optgroup,
.gctClient-app select,
.gctClient-app textarea {
    font-feature-settings: inherit;
    font-variation-settings: inherit;
    font-family: inherit;
    font-size: 100%;
    font-weight: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    color: inherit;
    margin: 0;
    padding: 0
}

.gctClient-app button,
.gctClient-app select {
    text-transform: none
}

.gctClient-app button,
.gctClient-app input:where([type=button]),
.gctClient-app input:where([type=reset]),
.gctClient-app input:where([type=submit]) {
    -webkit-appearance: button;
    background-color: #0000;
    background-image: none
}

.gctClient-app :-moz-focusring {
    outline: auto
}

.gctClient-app :-moz-ui-invalid {
    box-shadow: none
}

.gctClient-app progress {
    vertical-align: baseline
}

.gctClient-app ::-webkit-inner-spin-button {
    height: auto
}

.gctClient-app ::-webkit-outer-spin-button {
    height: auto
}

.gctClient-app [type=search] {
    -webkit-appearance: textfield;
    outline-offset: -2px
}

.gctClient-app ::-webkit-search-decoration {
    -webkit-appearance: none
}

.gctClient-app ::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit
}

.gctClient-app summary {
    display: list-item
}

.gctClient-app blockquote,
.gctClient-app dl,
.gctClient-app dd,
.gctClient-app h1,
.gctClient-app h2,
.gctClient-app h3,
.gctClient-app h4,
.gctClient-app h5,
.gctClient-app h6,
.gctClient-app hr,
.gctClient-app figure,
.gctClient-app p,
.gctClient-app pre {
    margin: 0
}

.gctClient-app fieldset {
    margin: 0;
    padding: 0
}

.gctClient-app legend {
    padding: 0
}

.gctClient-app ol,
.gctClient-app ul,
.gctClient-app menu {
    margin: 0;
    padding: 0;
    list-style: none
}

.gctClient-app dialog {
    padding: 0
}

.gctClient-app textarea {
    resize: vertical
}

.gctClient-app input::placeholder,
.gctClient-app textarea::placeholder {
    opacity: 1;
    color: #9ca3af
}

.gctClient-app button,
.gctClient-app [role=button] {
    cursor: pointer
}

.gctClient-app :disabled {
    cursor: default
}

.gctClient-app img,
.gctClient-app svg,
.gctClient-app video,
.gctClient-app canvas,
.gctClient-app audio,
.gctClient-app iframe,
.gctClient-app embed,
.gctClient-app object {
    vertical-align: middle;
    display: block
}

.gctClient-app img,
.gctClient-app video {
    max-width: 100%;
    height: auto
}

.gctClient-app [hidden] {
    display: none
}

.gctClient-app *,
.gctClient-app:before,
.gctClient-app:after,
.gctClient-app ::backdrop {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x: ;
    --tw-pan-y: ;
    --tw-pinch-zoom: ;
    --tw-scroll-snap-strictness: proximity;
    --tw-gradient-from-position: ;
    --tw-gradient-via-position: ;
    --tw-gradient-to-position: ;
    --tw-ordinal: ;
    --tw-slashed-zero: ;
    --tw-numeric-figure: ;
    --tw-numeric-spacing: ;
    --tw-numeric-fraction: ;
    --tw-ring-inset: ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: #3b82f680;
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur: ;
    --tw-brightness: ;
    --tw-contrast: ;
    --tw-grayscale: ;
    --tw-hue-rotate: ;
    --tw-invert: ;
    --tw-saturate: ;
    --tw-sepia: ;
    --tw-drop-shadow: ;
    --tw-backdrop-blur: ;
    --tw-backdrop-brightness: ;
    --tw-backdrop-contrast: ;
    --tw-backdrop-grayscale: ;
    --tw-backdrop-hue-rotate: ;
    --tw-backdrop-invert: ;
    --tw-backdrop-opacity: ;
    --tw-backdrop-saturate: ;
    --tw-backdrop-sepia: ;
    --tw-contain-size: ;
    --tw-contain-layout: ;
    --tw-contain-paint: ;
    --tw-contain-style:
}